import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const UserEducation = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [userQualifications, setUserQualifications] = useState([]);
  const [qualifications, setQualifications] = useState([]);
  const [fieldOfStudies, setFieldOfStudies] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState('');
  const [isFocused, setIsFocused] = useState('');
  const [errors, setErrors] = useState({});
  const [modalButtonGroup, setModalButtonGroup] = useState('createQualification');

  
  const [formData, setFormData] = useState({
    id: '',
    qualification: '',
    fieldOfStudy: '',
    fromDate: '',
    toDate: '',
    institution: '',
    location: '',
  });

  const imageBaseUrl = `${network.serverip}/images/`;

  const handleQualificationCreate = () => {
    setModalVisible(true);
    setModalButtonGroup('createQualification');
    setFormData({
      id: '',
      qualification: '',
      fieldOfStudy: '',
      fromDate: '',
      toDate: '',
      institution: '',
      location: '',
    });
  }

  const handleQualificationEdit = (data) => {
    setModalButtonGroup('editQualification');
    setModalVisible(true);
    setFormData({
      id: data?.id || '',
      qualification: data?.qualification?.title || '',
      fieldOfStudy: data?.field_of_study?.title || '',
      fromDate: data?.from_date || '',
      toDate: data?.to_date || '',
      institution: data?.institution || '',
      location: data?.location || '',
    });
  }

  const handleClose = () => {
    setModalVisible(false);
    setFormData({
      id: '',
      qualification: '',
      fieldOfStudy: '',
      fromDate: '',
      toDate: '',
      institution: '',
      location: '',
    });
    setErrors({});
    setIsFocused('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSuggestionClick = (e, field, value) => {
    e.preventDefault();
    setFormData({ ...formData, [field]: value });
    setShowSuggestions('');
    setFilteredSuggestions([]);
    setIsFocused('');
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const fetchUserQualifications = async () => {

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    try {
      const response = await axios.get(`${network.serverip}/api/user-qualification-list-web/${userId}`);

      if (response.data.success && isMountedRef.current) {

        const userQualificationData = response.data.data || [];

        const sortedData = userQualificationData.sort((a, b) =>
          new Date(b.to_date || 0) - new Date(a.to_date || 0)
        );

        setUserQualifications(sortedData);
        console.log('This is the sorted fetched user qualifications:', sortedData);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUserQualifications([]);
        }
        console.error('Failed to fetch user qualifications');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUserQualifications([]);
      }
      console.error('Error fetching user qualifications:', error);
    }
  };

  const fetchQualifications = async () => {
    try {
      const response = await axios.get('/qualifications-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const qualificationData = (response.data.data || []);
        const titles = qualificationData.map(item => item.title);
        setQualifications(titles);
        console.log('Fetched qualifications:', qualificationData);
      } else if (!response.data.success) {
        console.error('Failed to fetch qualifications');
      }

    } catch (error) {
      console.error('Error fetching qualifications:', error);
    }
  };

  const fetchFieldOfStudies = async () => {
    try {
      const response = await axios.get('/fieldofstudies-list-web', {
        baseURL: `${network.serverip}/api`,
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (response.data.success && isMountedRef.current) {
        const fieldofstudyData = (response.data.data || []);
        const titles = fieldofstudyData.map(item => item.title);
        setFieldOfStudies(titles);
        console.log('Fetched field of studies:', fieldofstudyData);
      } else if (!response.data.success) {
        console.error('Failed to fetch field of studies');
      }

    } catch (error) {
      console.error('Error fetching field of studies:', error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setModalVisible(false);
        setFormData({
          id: '',
          qualification: '',
          fieldOfStudy: '',
          fromDate: '',
          toDate: '',
          institution: '',
          location: '',
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSaveAndAddAnother = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setFormData({
          id: '',
          qualification: '',
          fieldOfStudy: '',
          fromDate: '',
          toDate: '',
          institution: '',
          location: '',
        });
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async () => {

    console.log('This is the formData', formData);

    setErrors({});

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    let isValid = true;

    if (!formData.qualification) {
      setErrors(prevErrors => ({
        ...prevErrors,
        qualification: 'Please enter a level of education'
      }));
      isValid = false;
    }

    if (!formData.fieldOfStudy) {
      setErrors(prevErrors => ({
        ...prevErrors,
        fieldOfStudy: 'Please enter a field of study'
      }));
      isValid = false;
    }

    if (!formData.fromDate) {
      setErrors(prevErrors => ({
        ...prevErrors,
        fromDate: 'Please select a start date'
      }));
      isValid = false;
    }

    if (!formData.toDate) {
      setErrors(prevErrors => ({
        ...prevErrors,
        toDate: 'Please select a completion date'
      }));
      isValid = false;
    }

    const fromDate = formData.fromDate ? new Date(formData.fromDate) : null;
    const toDate = formData.toDate ? new Date(formData.toDate) : null;

    if (fromDate && toDate && fromDate > toDate) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        toDate: "The start date can not be later than completion date",
        fromDate: "The start date can not be later than completion date",
      }));
    }

    if (!isValid) {
      return { success: false };
    }

    try {

      formData.userId = userId;

      const response = await axios.post(
        `${network.serverip}/api/user-qualification-${formData.id ? 'edit' : 'create'}-web`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.success && isMountedRef.current) {
        console.log(`User qualification uploaded successfully:`, response.data);
        fetchUserQualifications();
        fetchQualifications();
        fetchFieldOfStudies();
        return { success: true };
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
        return { success: false };
      }

    } catch (error) {
      console.error('Error uploading user qualification:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

        return { success: false };

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));

        return { success: false };

      }
    }
  }

  const handleQualificationDelete = async (data) => {

    console.log('handle qualification delete clicked');

    try {

      const response = await axios.delete(`${network.serverip}/api/user-qualification-delete-web/${data.id}`);

      if (response.data.success && isMountedRef.current) {
        console.log('User qualification deleted successfully:', response.data);
        fetchUserQualifications();
        fetchQualifications();
        fetchFieldOfStudies();
        setFormData({
          id: '',
          qualification: '',
          fieldOfStudy: '',
          fromDate: '',
          toDate: '',
          institution: '',
          location: '',
        });
        setModalVisible(false);
        setErrors({});
      } else {
        console.error('Failed to delete user qualification:', response.data);
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    } catch (error) {
      console.error('Failed to delete user qualification:');
      setErrors(prevErrors => ({
        ...prevErrors,
        uploadError: 'Something went wrong, please try again',
      }));
    }

  }


  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    fetchUserQualifications();
    fetchQualifications();
    fetchFieldOfStudies();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);


  const styles = UserEducationStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => { }}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Education
                      </h4>
                    </button>

                    <div>
                      {userQualifications && userQualifications.length > 0 ? (
                        userQualifications.map((qual, index) => {
                          const fromDate = qual.from_date ? new Date(qual.from_date) : null;
                          const toDate = qual.to_date ? new Date(qual.to_date) : null;

                          const formattedFromDate = fromDate
                            ? fromDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                            : 'Start date not available';

                          const formattedToDate = toDate
                            ? toDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' })
                            : 'End date not available';

                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i className="fas fa-graduation-cap font-size-4 text-success"></i>
                                </div>
                                <div className="d-flex justify-content-between w-100">
                                  <div className="w-100">
                                    <p className="mb-0 font-size-4 text-black-2 font-weight-semibold">
                                      {qual?.qualification?.title}
                                    </p>
                                    <p className="font-size-4 text-default-color line-height-2">
                                      {qual?.field_of_study?.title} {`, ${qual?.institution}`}
                                    </p>
                                    <div className="d-flex align-items-center justify-content-md-between flex-wrap">
                                      <span className="font-size-4 text-gray mr-5">
                                        {`${formattedFromDate} - ${formattedToDate}`}
                                      </span>
                                      <span className="font-size-3 text-gray">
                                        <i className="fas fa-map-marker-alt mr-4"></i>
                                        {qual?.location}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-start">
                                    <button
                                      style={styles.editButton}
                                      onClick={() => { handleQualificationEdit(qual) }}
                                    >
                                      <i className="fas fa-pencil-alt text-success font-size-4"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p></p>
                      )}
                    </div>
                    <div className="w-100 pt-9 mb-9 border-top pr-11">
                      <button
                        className="w-100 d-flex align-items-center justify-content-between"
                        onClick={() => { handleQualificationCreate() }}
                        style={styles.addButton}
                      >
                        <p className="font-size-4 text-dodger font-weight-semibold mb-0">
                          {userQualifications.length > 0 ? 'Add another education' : 'Add education'}
                        </p>
                        <i className="fas fa-plus text-success font-size-4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        size="lg"
        centered
        show={modalVisible}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <form onSubmit={handleSave} className="bg-white rounded-8 overflow-hidden">
            <div className="p-10 w-100">
              <div className="d-flex justify-content-center border-bottom border-mercury pb-2">
                <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                  {modalButtonGroup === 'createQualification'
                    ? 'Add Education'
                    : 'Edit Education'}
                </h4>
              </div>
              <div className="w-100 border-bottom border-mercury mb-5">

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="qualification"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Level of education:
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter the education"
                    id="qualification"
                    name="qualification"
                    value={formData.qualification}
                    onChange={(e) => {
                      handleChange(e);
                      const text = e.target.value;
                      const filtered = qualifications.filter((qualification) =>
                        qualification.toLowerCase().includes(text.toLowerCase())
                      );
                      setFilteredSuggestions(filtered);
                      setShowSuggestions(text.length > 0 ? 'qualification' : '');
                    }}
                    onFocus={() => setIsFocused('qualification')}
                    // onBlur={() => setIsFocused('')}
                    onBlur={(e) => {
                      if (!e.relatedTarget || !e.relatedTarget.classList.contains("suggestion-item")) {
                        setIsFocused('');
                        setShowSuggestions('');
                      }
                    }}
                    style={isFocused === 'qualification' ? styles.focussedInput : {}}
                  />
                  {showSuggestions === 'qualification' && filteredSuggestions.length > 0 && (
                    <div
                      className="suggestions bg-white border border-gray"
                      style={styles.suggestionContainer}
                    >
                      {filteredSuggestions.map((suggestion, index) => (
                        <button
                          key={index}
                          className="suggestion-item font-size-4 text-black-2 mt-0 p-2 border-bottom w-100 text-left"
                          style={styles.suggestionItemStyle}
                          onClick={(e) => handleSuggestionClick(e, 'qualification', suggestion)}
                        >
                          {suggestion}
                        </button>
                      ))}
                    </div>
                  )}
                  {errors?.qualification ? <p className="font-size-3 text-danger">
                    {errors?.qualification}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="fieldOfStudy"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Field of study:
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Enter the field of study"
                    id="fieldOfStudy"
                    name="fieldOfStudy"
                    value={formData.fieldOfStudy}
                    onChange={(e) => {
                      handleChange(e);
                      const text = e.target.value;
                      const filtered = fieldOfStudies.filter((fieldOfStudy) =>
                        fieldOfStudy.toLowerCase().includes(text.toLowerCase())
                      );
                      setFilteredSuggestions(filtered);
                      setShowSuggestions(text.length > 0 ? 'fieldOfStudy' : '');
                    }}
                    onFocus={() => setIsFocused('fieldOfStudy')}
                    // onBlur={() => setIsFocused('')}
                    onBlur={(e) => {
                      if (!e.relatedTarget || !e.relatedTarget.classList.contains("suggestion-item")) {
                        setIsFocused('');
                        setShowSuggestions('');
                      }
                    }}
                    style={isFocused === 'fieldOfStudy' ? styles.focussedInput : {}}
                  />
                  {showSuggestions === 'fieldOfStudy' && filteredSuggestions.length > 0 && (
                    <div
                      className="suggestions bg-white border border-gray"
                      style={styles.suggestionContainer}
                    >
                      {filteredSuggestions.map((suggestion, index) => (
                        <button
                          key={index}
                          className="suggestion-item font-size-4 text-black-2 mt-0 p-2 border-bottom w-100 text-left"
                          style={styles.suggestionItemStyle}
                          onClick={(e) => handleSuggestionClick(e, 'fieldOfStudy', suggestion)}
                        >
                          {suggestion}
                        </button>
                      ))}
                    </div>
                  )}
                  {errors?.fieldOfStudy ? <p className="font-size-3 text-danger">
                    {errors?.fieldOfStudy}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="institution"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Institution:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the institution name"
                    id="institution"
                    name="institution"
                    value={formData.institution}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('institution')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'institution' ? styles.focussedInput : {}}
                  />
                  {errors?.institution ? <p className="font-size-3 text-danger">
                    {errors?.institution}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="location"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Location:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the location name"
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('location')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'location' ? styles.focussedInput : {}}
                  />
                  {errors?.location ? <p className="font-size-3 text-danger">
                    {errors?.location}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="fromDate"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Select start date:
                  </label>
                  <div className="w-100">
                    <DatePicker
                      selected={formData.fromDate}
                      onChange={(date) => {
                        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                        setFormData({ ...formData, fromDate: formattedDate });
                      }}
                      placeholderText="Select a date"
                      id="fromDate"
                      name="fromDate"
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      autoComplete="off"
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors?.fromDate ? <p className="font-size-3 text-danger">
                    {errors?.fromDate}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="toDate"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Select completion date:
                  </label>
                  <div className="w-100">
                    <DatePicker
                      selected={formData.toDate}
                      onChange={(date) => {
                        const formattedDate = date ? format(date, 'yyyy-MM-dd') : '';
                        setFormData({ ...formData, toDate: formattedDate });
                      }}
                      placeholderText="Select a date"
                      id="toDate"
                      name="toDate"
                      className="form-control"
                      dateFormat="yyyy-MM-dd"
                      autoComplete="off"
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  {errors?.toDate ? <p className="font-size-3 text-danger">
                    {errors?.toDate}
                  </p> : null}
                </div>
              </div>

              <div className="w-100 d-flex justify-content-end">
                {modalButtonGroup === 'createQualification' ? (
                  <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAndAddAnother}
                      type="button"
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Save and add another
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => { handleQualificationDelete(formData) }}
                      className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Delete
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                )}
              </div>

              <div className="w-100 d-flex justify-content-center">
                {errors?.uploadError ? <p className="font-size-3 text-danger">
                  {errors?.uploadError}
                </p> : null}
              </div>

            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UserEducation;

function UserEducationStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

